import React from 'react';
import { HStack, Stack, Text } from '@arcault-ui/shared-components';

const RFPSummary = ({ rfp }) => {

  const formatData = (value, type) => {
    if (type && type.toLowerCase() === 'currency') {
      return `$${Number(value).toLocaleString('en-US')}`;
    }
    return value;
  }

  return (
    <Stack className="rfp-summary" variant="center">
      <div className="lead-meta-data">
        {(rfp?.profileData || []).map((metaData) => (
          <HStack variant="stretch" key={metaData.id}>
            <Text variant="body"><b>{metaData.label}</b></Text>
            <Text variant="body">{formatData(metaData.value, metaData.type)}</Text>
          </HStack>
        ))}
      </div>
      <Text variant="h3">Summary</Text>
      <Text variant="body">{rfp.summary}</Text>
      <Text variant="h3">Notes</Text>
      <Text variant="body">{rfp.notes}</Text>
    </Stack>
  );
};

export default RFPSummary;
