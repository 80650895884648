import { Card, HStack, Stack, Text, Icon } from '@arcault-ui/shared-components';

import './Leads.css';
import { statusDisplay } from './constants';
const SUMMARY_WORD_LIMIT = 30;

const LeadsCardView = ({ data, onClick }) => {
    const truncateSummary = (summary) => {
        const words = summary.split(' ');
        return words.length >= SUMMARY_WORD_LIMIT ? words.slice(0, SUMMARY_WORD_LIMIT).join(' ') + '...' : summary;
    };

    return (
        <Card 
            onClick={() => onClick(data.id)}
            className={`neu-card-low lead-card ${data.status === 'OPEN' ? 'dot' : ''}`}
            footer={(
                <HStack variant="end" className="lead-card-footer">
                    <Icon name="delete" />
                </HStack>
            )}>
            <Stack className="lead-card-inner" variant="start">
                <div>{statusDisplay(data.status)}</div>
                <div className="lead-card-title">
                    <Text variant="subheading">{data.title}</Text>
                </div>
                <Text variant="caption">Location: {data.geography}</Text>
                <Text variant="body-large">{truncateSummary(data.summary)}</Text>
            </Stack>
        </Card>
    );
};

export default LeadsCardView;