import { Card, HStack, Stack, Text, Icon } from '@arcault-ui/shared-components';

import '../leads/Leads.css';

const CustomerCardView = ({ data, onClick }) => {


    return (
        <Card 
            onClick={() => onClick(data.id)}
            className={`neu-card-low customer-card ${data.status === 'OPEN' ? 'dot' : ''}`}
            footer={(
                <HStack variant="end" className="customer-card-footer">
                    <Icon name="call" /> | <Icon name="email" />
                </HStack>
            )}>
            <Stack className="customer-card-inner arcault-stack-low-gap" variant="start">
                <div className="customer-card-title">
                    <Text variant="h3">{data.name}</Text>
                </div>
                <Text variant="caption">Created: {data.updatedAt}</Text>
            </Stack>
        </Card>
    );
};

export default CustomerCardView;