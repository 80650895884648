import React, {useState} from 'react';
import { Icon, Stack } from '@arcault-ui/shared-components'
import { Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, List, ListItem, ListItemIcon, Avatar } from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Settings as SettingsIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { Flex, Drawer } from '@chakra-ui/react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import Header from './Header'
import {useAuthenticator} from "@aws-amplify/ui-react"

const CallButton = () => (
  <div className="call-button">
    <Icon name="call" />
  </div>
)

export default function CRMLayout() {
  const location = useLocation();
  const isProfileNav = location.pathname.includes('/profile');
  
  const navigate = useNavigate(); // Hook for navigation
  const [selectedContact, setSelectedContact] = useState(null);
  const [callDrawerOpen, setCallDrawerOpen] = useState(false);
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const selectContact = (contact) => {
    // setContacts(contacts.filter(contact => contact.id !== contactId));
    setPhoneNumber(contact.phone)
    setSelectedContact(contact)
  };
  const [currentView, setCurrentView] = useState('connect');
  const switchViews = (view) =>{
      setCurrentView(view)
      navigate("/"+view)
      setDrawerOpen(false)
  }
  const handleDialButton = (key) => {
    setPhoneNumber(phoneNumber + key.value);
  };

  
  
  const [editMode, setEditMode] = useState(false);
  const selectActivity = (activity) => {
    
    if(activity){
      setEditMode(true)
      setSelectedActivity(activity)
    }
    else{
      setEditMode(false)
      setSelectedActivity({
        date: "5/27/1977", 
        notes: '',
        type: 'Note'
      })
    }
    
    
  };
  
  
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const {route, user,  signOut} = useAuthenticator(context => [
    context.route,
    context.user,
    context.signOut
    ])
  
  return (
    <div>
      <div className="background-layer" />
      <CallButton onClick={() => setCallDrawerOpen(true)} />
        <Drawer isOpen={callDrawerOpen} >test</Drawer>
      <Stack className="main-stack">
        <Header isProfileNav={isProfileNav} signOut={signOut} user={{}} />
        <Flex flex="1" >
          <div className="main-content">
            <Outlet />
          </div>
        </Flex>
      </Stack>
    </div>
  );
}