import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUserAttributes } from 'aws-amplify/auth';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials'
import { Avatar } from '@arcault-ui/shared-components'
import { Box, Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import { ChakraProvider } from '@chakra-ui/react'
import { userProfileAPI } from '../actions/profile';

const TopBarProfileIcon = ({signOut, handleCloseUserMenu }) => {
  
  const axiosWithCredentials = useAxiosWithCredentials()
  const [user, setUser] = useState({name: ''}); // Initialize a key state
  const navigate = useNavigate();

  const loadUserInfo = async () => {
    try {
      //try to get name from cognito first
      const response = await userProfileAPI.get();
      const userAttributes = response.data;
      setUser({...userAttributes, name: `${userAttributes.givenName} ${userAttributes.familyName || ''}`});
      /*const userAttributes = await fetchUserAttributes();
      if(userAttributes.given_name){
        setUser({...user, name: `${userAttributes.given_name} ${userAttributes.family_name || ''}`});
      }*/
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    loadUserInfo();
  }, []);

  const options = [
    {label: 'Profile', to: 'profile'},
    {label: 'Company Profile', to: 'company'},
    {label: 'Logout', onClick: signOut }
  ];
    
  const handleNavigation = (location) => {
    navigate('/' + location); // Navigates to the About page
  }

  return (
    <ChakraProvider>
      <Box sx={{ flexGrow: 0 }}>
       <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          className="profile-icon"
          icon={
            <Avatar
              user={{ 
                name: user.name,
                avatarUrl: user.avatarUrl
              }}
            />
          }
        />
        <MenuList>
          {options.map((option) => (
            <MenuItem key={option.label} onClick={handleCloseUserMenu}>
              {option.to ? (
                <MenuItem component={Link} onClick={() => handleNavigation(option.to)}>{option.label}</MenuItem>
              ) : (
                <MenuItem  onClick={option.onClick}>{option.label}</MenuItem>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  </ChakraProvider>
  );
};
export default TopBarProfileIcon;