import React, { useState, useEffect } from 'react';
import { Button, Form, Icon, Stack, HStack, Card, Modal, Table, Text, Vault } from '@arcault-ui/shared-components';
import { customersAPI } from '../../actions/customers';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CardView from './CustomerCardView';
import { useParams } from 'react-router-dom';
import useDocuments from '../../hooks/useDocuments';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [activeCustomer, setActiveCustomer] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const loadItems = async () => {
    const data = await customersAPI.get();
    setCustomers(data.data.map(c => ({
      ...c,
      name: c.firstName + ' ' + c.lastName,
    })));
  };

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    if (id && customers?.length > 0) setActiveCustomer(customers.find(c => c.id === id));
  }, [id, customers]);

  const addCustomer = async (data) => {
    await customersAPI.save(data);
    setShowAddCustomer(false);
    await loadItems();
  }

  const columns = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email address', align: 'left' },
    { id: 'phone', label: 'Phone number', align: 'left' },
    { id: 'address', label: 'Location', align: 'left' },
  ];

  const actions = [
    {
      label: 'Edit',
      iconName: 'edit',
      onClick: (row) => alert(`Editing ${row.name}`),
    },
    {
      label: 'Delete',
      iconName: 'delete',
      onClick: (row) => alert(`Deleting ${row.name}`),
    },
  ];


  return (
    <Card className="card-no-shadow leads-dashboard dashboard">
      <Modal title="Add Customer" isOpen={showAddCustomer} onClose={() => setShowAddCustomer(false)}>
        <Form updateFunction={addCustomer} fields={[
          {
            id: 'firstName',
            label: 'First Name',
          },
          {
            id: 'lastName',
            label: 'Last Name',
          },
          {
            id: 'phone',
            label: 'Phone',
            type: 'phone'
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
          },
          {
            id: 'address',
            label: 'Address',
            fullWidth: true,
          },
          {
            id: 'notes',
            label: 'Notes',
            type: 'textarea',
            fullWidth: true,
          },
        ]}
      />
      </Modal>
      <HStack className="table-header" variant="spread">
        <Text variant="h1">Customer</Text>
        {!id && (
          <Button type="primary" onClick={() => setShowAddCustomer(true)}>Add Customer +</Button>
        )}
      </HStack>
      {!id && (<Table
        isLoading={false}
        defaultViewType="card"
        CardView={CardView}
        columns={columns}
        rows={customers}
        actions={actions}
        service="customer"
        displayValue={(value, column) => {
          if (column.id === 'tags' && value) {
            return value.join(', ');
          }
          return value;
        }}
        onClick={
          (id) => navigate(`/customers/${id}`)// Navigate to the routed individual lead
          // (id) => setActiveCustomer(customers.find(c => c.id === id ))
        }
      />)}
      {id && (
        <Stack className="leads-dashboard dashboard" variant="start">
          <Text variant="h2">
            <div onClick={() => navigate('/customers')}>
              <Icon name="arrow_back_ios" />
            </div> {activeCustomer?.name}
          </Text>
          <HStack className="full-height-card leads-dashboard-container">
            <div className="customer-info">
              {activeCustomer?.email && <div className="customer-info-item"><Icon name="email" />{activeCustomer?.email}</div>}
              {activeCustomer?.phone && <div className="customer-info-item"><Icon name="phone" />{activeCustomer?.phone}</div>}
              {activeCustomer?.address && <div className="customer-info-item"><Icon />{activeCustomer?.address}</div>}
              
            </div>
            <div className="customer-vault">
              {activeCustomer?.userId && <Vault 
                category={`crm_${activeCustomer?.userId}`}
                useDocuments={useDocuments}
              />}
            </div>
          </HStack>
        </Stack>
      )}
    </Card>
  );
};

export default Customers;