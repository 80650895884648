import { Tag } from '@arcault-ui/shared-components';


export const RFP_NON_RESPONSE = 'OPEN';
export const RFP_RESPONSE = 'SENT';
export const RFP_RESPONSE_ACCEPTED = 'rfp-response-accepted';
export const RFP_RESPONSE_REJECTED = 'rfp-response-rejected';

export const humanReadableStatus = (value) => {
    switch (value) {
      case RFP_NON_RESPONSE:
        return "Active";
      case RFP_RESPONSE:
        return "Proposal Sent";
      case RFP_RESPONSE_ACCEPTED:
        return "Proposal Accepted";
      case RFP_RESPONSE_REJECTED:
        return "Proposal Rejected";
    }
}

export const statusDisplay = (value) => {
    switch (value) {
      case RFP_NON_RESPONSE: 
        return <Tag label="Active" variant="primary" />;
      case RFP_RESPONSE:
        return (<Tag label="Proposal Sent" variant="accent" />);
      case RFP_RESPONSE_ACCEPTED:
        return (<Tag label="Proposal Accepted" variant="secondary" />);
      case RFP_RESPONSE_REJECTED:
        return (<Tag label="Proposal Rejected" variant="primary" />);      
    }
}

/*export const actionsProducer = (actions) => (<>
    {actions.filter(a => a.condition ? a.condition(row) : true)?.map((action, index) => (
        <IconButton
        key={index}
        className={action.iconClassName}
        aria-label={action.label}
        onClick={() => action.onClick(row)}
        >
        <Icon name={action.iconName} />
        </IconButton>
    ))}
</>);*/