import { useState, useEffect } from 'react';
import { Box, Typography, Avatar, IconButton, Tooltip } from '@mui/material';
import { LinkedIn as LinkedInIcon, Twitter as TwitterIcon, Facebook as FacebookIcon, GitHub as GitHubIcon, Language as WebsiteIcon } from '@mui/icons-material';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
const socialIcons = {
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
  facebook: FacebookIcon,
  github: GitHubIcon,
  website: WebsiteIcon
};

const CustomerHeader = ({ contactId }) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [contactDetails, setContactDetails] = useState({});
  
  const loadContactDetails = async () => {
    try {
      const response = await axiosWithCredentials.get(`/crm/contact/meta/${contactId}`);
      setContactDetails(response.data);
    } catch (error) {
      console.error("Error loading contact details:", error);
    }
  };
  useEffect(() => {
    loadContactDetails();
  }, [contactId]);


  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 2 }}>
      <Avatar alt={contactDetails.firstName + " "+ contactDetails.lastName } src={contactDetails.profilePic} sx={{ width: 64, height: 64 }} />
      <Box>
        <Typography variant="h5">{contactDetails.firstName + " "+ contactDetails.lastName }</Typography>
        <Typography variant="subtitle1" color="text.secondary">{contactDetails.title}</Typography>
        <Box sx={{ display: 'flex', mt: 1 }}>
          {contactDetails.socialLinks?.map(([key, link]) => {
            const SocialIcon = socialIcons[key];
            return (
              <Tooltip key={key} title={`Connect on ${key.charAt(0).toUpperCase() + key.slice(1)}`}>
                <IconButton 
                  component="a" 
                  href={link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  color="primary"
                  size="small"
                >
                  <SocialIcon />
                </IconButton>
              </Tooltip>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerHeader;
