import React, { useState, useEffect } from 'react';
import { Stack, HStack, Avatar, Text, Icon, Loading } from '@arcault-ui/shared-components';
import RFPSummary from './RFPSummary';
import ProposalForm from './ProposalForm';
import ProposalSummary from './ProposalSummary';
import { leadsAPI } from '../../actions/leads';
import { proposalsAPI } from '../../actions/proposals';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Tab, TabList, TabPanel } from '../Tabs';
import { companyProfileAPI } from '../../actions/profile';

const IndividualLead = ({ activeRFPId }) => {
    const [lead, setLead] = useState(false);
    const [profile, setProfile] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [proposal, setProposal] = useState({});
    const [isSent, setIsSent] = useState(false);
    const [activeTab, setActiveTab] = useState('Customer Info');
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
      companyProfileAPI.get().then((data) => {
        setProfile(data?.data);
      })
    }, []);
  
    const getProposals = async () => {
      const data = await proposalsAPI.draft(lead.id)  
      const { id } = data.data;
        
      const savedData = await proposalsAPI.get(id)
      if (savedData.data && savedData.data[0]) {
        console.log('data', savedData)
        if (savedData.data[0].status === 'SENT') {
          setIsSent(true);
        }
        setProposal(savedData.data[0]);
      } else {
        setProposal(data.data);
      }
      setIsLoading(false);
    }
    
    const loadLead = async (id) => {
        setIsLoading(true);
        try {
            const data = await leadsAPI.view(id);
            setLead(data.data);
        } catch (error) {
            console.error("Error loading files:", error);
        }
        
    }

    useEffect(() => {
        if (lead?.id) {
            getProposals();
        }
    }, [lead])

    useEffect(() => {
        if (activeRFPId) {
            loadLead(activeRFPId);
        }
    }, [activeRFPId])

    return (
        <Stack className="leads-dashboard" variant="start">
            <Text variant="h2">
                <div onClick={() => navigate('/leads')}>
                <Icon name="arrow_back_ios" />
                </div>
                {lead.title}
            </Text>
            
            <Stack className="full-height-card leads-dashboard-container">
                  <TabList>
                    <Tab activeTab={activeTab} label="Customer Info" onClick={setActiveTab}>
                      Customer Info
                    </Tab>
                    <Tab activeTab={activeTab} label="Proposal" onClick={setActiveTab}>
                      Proposal
                    </Tab>
                  </TabList>
            
                  <TabPanel activeTab={activeTab} label="Customer Info">
                    <RFPSummary rfp={lead} />
                  </TabPanel>
                  <TabPanel activeTab={activeTab} label="Proposal">
                    <>
                      {isLoading && <Loading />}                  
                      <div className="your-proposal neu-card-min">
                        <HStack variant="spread" className="proposal-header">
                          <HStack variant="start" className="proposal-header-left">
                            <Avatar user={{ avatarUrl: profile?.avatarUrl }} />
                            <Text variant="h1">{profile?.name}</Text>
                          </HStack>
                          <Stack variant="center" className="proposal-header-right">
                            <Text variant="subheading">{profile?.address}</Text>
                            <Text variant="subheading">{profile?.url}</Text>
                          </Stack>
                        </HStack>
                        {(!isSent) && <ProposalForm proposal={proposal} setIsSent={setIsSent} setProposal={setProposal}  lead={lead} />}
                        {isSent && <ProposalSummary proposal={proposal} />}
                      </div>
                    </>
                </TabPanel>
            </Stack>
        </Stack>
    );
};

export default IndividualLead;