import React, { useState } from 'react';

import { Card, Text, Stack, HStack, Avatar } from '@arcault-ui/shared-components';
import { userProfileAPI } from '../../actions/profile';
import './Profile.css'
import Form from '../common/Form';


const fields = [
  {
    id: 'givenName',
    label: 'Given Name'
  },
  {
    id: 'familyName',
    label: 'Family Name'
  },
  { id: 'phone',
    label: 'Phone'
  },
  {
    id: 'avatarUrl',
    label: 'Photo URL'
  }
];

const Profile = () => {
  const [profile, setProfile] = useState({});

  return (
    <HStack variant="center">
    <Card className="profile-card">
      <Text variant="h2">
        Profile
      </Text>
      <Stack>
        <HStack variant="center" className="profile-container">
          <Avatar
            alt="Profile Picture"
            user={{ 
              ...profile,
              name: `${profile.givenName} ${profile.familyName}`
            }}
          />
        </HStack>
        <Form fields={fields} updateFunction={userProfileAPI.update} getFunction={userProfileAPI.get} dataCallback={setProfile} />
      </Stack>
    </Card>
    </HStack>
  );
};

export default Profile;

