import React, { useState, useEffect } from 'react';

import { Card, Text, Stack, HStack,  Avatar } from '@arcault-ui/shared-components';
import { companyProfileAPI } from '../../actions/profile';
import './Profile.css'
import Form from '../common/Form';

const fields = [
  {
    id: 'name',
    label: 'Company Name'
  },
  {
    id: 'url',
    label: 'Website'
  },
  { id: 'address',
    label: 'Address'
  },
  {
    id: 'description',
    label: 'Description',
    type: 'textarea',
    fullWidth: true,
  }, 
  {
    id: 'avatarUrl',
    label: 'Logo URL'
  }
];

const Profile = () => {
  const [profile, setProfile] = useState({});

  return (
    <HStack variant="center">
    <Card className="profile-card">
      <Text variant="h2">
        Company Profile
      </Text>
      <Stack>
        <HStack variant="center" className="profile-container">
          <Avatar
            alt="Profile Picture"
            user={{ 
              ...profile,
            }}
          />
        </HStack>
        <Form fields={fields} updateFunction={companyProfileAPI.update} getFunction={companyProfileAPI.get} dataCallback={setProfile} />
      </Stack>
    </Card>
    </HStack>
  );
};

export default Profile;

