import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemText, Collapse, Typography, IconButton } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';

// Helper function to format activity text for display
const formatActivityText = (activity) => {
  switch (activity.type) {
    case 'note':
      return `Note: ${activity.details}`;
    case 'email':
      return `Email: ${activity.details}`;
    case 'call':
      return `Call: ${activity.details}`;
    // Handle other types as necessary
    default:
      return activity.details;
  }
};

const ActivityLog = ({contactId}) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [expandedActivityId, setExpandedActivityId] = useState(null);
  const [activities, setActivities] = useState([]);

  const toggleExpandActivity = (activityId) => {
    setExpandedActivityId(expandedActivityId === activityId ? null : activityId);
  };

  const formatTimestamp = (timestamp) => {
    // Format timestamp as needed, e.g., to local time string
    return new Date(timestamp).toLocaleString();
  };
  const loadItems = async () => {
    try {
      const response = await axiosWithCredentials.get(`/crm/activity/ua/${contactId}`);
      console.log(JSON.stringify(response))
      setActivities(response.data)
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };
   useEffect(() => {
      loadItems();
    }, [contactId]); 

  return (
    <Box sx={{ height: '350px', overflowY: 'auto', bgcolor: 'background.paper' }}>
      <List>
        {activities.map((activity) => (
          <React.Fragment key={activity.typeKey}>
            <ListItem 
              button 
              dense
              onClick={() => toggleExpandActivity(activity.typeKey)} 
              sx={{ '&:hover': { bgcolor: 'action.hover' } }}>
              <ListItemText 
                primary={formatActivityText(activity)} 
                secondary={formatTimestamp(activity.createdOn)} />
            </ListItem>
            <Collapse in={expandedActivityId === activity.id} timeout="auto" unmountOnExit>
              <Box sx={{ pl: 4, pr: 2, pb: 2 }}>
                <Typography variant="body2">{activity.details}</Typography>
                {activity.type === "Recorded Call" && (
                  <Box mt={2}>
                    <audio controls src={activity.recordingUrl}>
                      Your browser does not support the audio element.
                    </audio>
                  </Box>
                )}
                {activity.type === "Recorded Video" && (
                  <Box mt={2}>
                    <video controls src={activity.recordingUrl}>
                      Your browser does not support the audio element.
                    </video>
                  </Box>
                )}
              </Box>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ActivityLog;