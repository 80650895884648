import React from 'react';

import { Stack, Text } from '@arcault-ui/shared-components';


const ProposalSummary = ({ proposal }) => {

  const displayValues = [
    { id: 'summary', label: 'Summary' },
    { id: 'scopeOfWork', label: 'Scope of Work' },
    { id: 'pricing', label: 'Pricing' },
    { id: 'timing', label: 'Timing' }
  ];
    const formatTime = (dateString) => {
      const date = new Date(dateString);
      const now = new Date();
      const diff = Math.abs(now - date);
      const diffHours = Math.floor(diff / (1000 * 60 * 60));
      const diffDays = Math.floor(diffHours / 24);

      if (diffHours < 1) {
        return 'less than an hour ago';
      } else if (diffHours < 24) {
        return `${diffHours} hours ago`;
      } else {
        return `${diffDays} days ago`;
      }
    };

    return (
      <Stack className="full-height-card">
        <Text variant="h2">Your Proposal</Text>
        <Text variant="caption"><em>sent {formatTime(proposal.updated)}</em></Text>
        {displayValues.map(d => (
          <React.Fragment key={d.id}>
            <Text variant="h3">{d.label}</Text>
            <Text variant="body">        
              {proposal[d.id]}
            </Text>
          </React.Fragment>
        ))}
      </Stack>
    );
  }

  export default ProposalSummary