// Layout.js
import React, {useState, useEffect} from 'react';
import { Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, Avatar } from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Settings as SettingsIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';

import { FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList'; // Import an icon for the filter


import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import ViewMenu from "../ViewMenu"

import ForumIcon from '@mui/icons-material/Forum';

import { Link } from 'react-router-dom';

import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

export default function Customers() {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [proposals, setProposals] = useState([]);  
  const [selectedProposal, setSelectedProposal] = useState(null);

  const loadItems = async () => {
    
  };
  
  
  useEffect(() => {
    loadItems();
  }, []); 
  
  return (
  <>
  <h1>Proposals</h1>
        <Grid xs={12}>
        <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Summary</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Tags</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
        </TableBody>
      </Table>
    </TableContainer>
        </Grid>
        </>
  );
}
